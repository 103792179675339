import Article from '@/views/Article'
import Home from '@/views/Home'
import _Layout from '@/views/_Layout'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: _Layout,
    children: [
      {
        path: '/',
        component: Home,
      },
      {
        path: '/article/:typeId/:id',
        component: Article,
      },
      {
        path: '/article/:typeId',
        component: Article,
      },
      {
        path: '/*',
        component: Article,
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes,
})

export default router
