import api from './api'

export function GET_typeAllAsync(params) {
  return api.get('/api/type-all', { params })
}

export function GET_typeDetailAsync(id) {
  return api.get('/api/type-detail/'.concat(id))
}

export function GET_articleListAsync(params) {
  return api.get('/api/article-list', { params })
}

export function GET_articleDetailAsync(id) {
  return api.get('/api/article-detail/'.concat(id))
}
