<template>
  <Spin :spinning="loading">
    <div class="panel-body">
      <div class="article-bar">
        <div class="article-bar--font-size">
          Cỡ chữ:
          <span @click="fontSizeChange(-0.1)" @selectstart.prevent>A-</span>
          <span @click="fontSizeChange(0.1)" @selectstart.prevent>A+</span>
        </div>
      </div>
      <div class="article-content" :style="{ zoom: fontSize }" v-html="content.titleContent" />
    </div>
  </Spin>
</template>
<script>
import { GET_articleDetailAsync } from '@/service'
import { Spin } from 'ant-design-vue'

export default {
  components: {
    Spin,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      content: {},
      fontSize: 1,
    }
  },
  watch: {
    id() {
      this.getArticleContentAsync()
    },
  },
  created() {
    this.getArticleContentAsync()
  },
  methods: {
    async getArticleContentAsync() {
      if (this.id) {
        this.loading = true
        const { data } = await GET_articleDetailAsync(this.id)
        const { Data } = data
        this.content = Data
        this.loading = false
      }
    },
    fontSizeChange(d) {
      this.fontSize += d
    },
  },
}
</script>
