import app from '@/main'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enUS from './en-US.json'
import viVN from './vi-VN.json'

export const LOCALE_KEY = '__LANG'

const messages = { 'en-US': enUS, 'vi-VN': viVN }

const langScope = Object.keys(messages)

Vue.use(VueI18n)

const locale = getLocale()

const i18n = new VueI18n({
  locale,
  messages,
})

export function getLocale() {
  const locale = localStorage.getItem(LOCALE_KEY)
  return langScope.includes(locale) ? locale : 'vi-VN'
}

export function setLocale(locale) {
  localStorage.setItem(LOCALE_KEY, locale)
  app.$store.dispatch('setLocale', locale)
}

export function switchLocale() {
  const locale = getLocale() === 'en-US' ? 'vi-VN' : 'en-US'
  i18n.locale = locale
  setLocale(locale)
}

export default i18n
