<template>
  <div class="tabs">
    <div class="tabs-nav">
      <RouterLink class="tabs-nav-item" to="/museum/hadotcb">
        History and Development of the Central Bank
      </RouterLink>
      <RouterLink class="tabs-nav-item" to="/museum/hovnc">
        History of Vietnam's Currencies
      </RouterLink>
    </div>
    <div class="tabs-content">
      <ArticleContent />
    </div>
  </div>
</template>
<script>
import { ArticleContent } from '@/components/views'

export default {
  components: {
    ArticleContent,
  },
}
</script>
