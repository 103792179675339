<template>
  <div class="container">
    <header class="top-header">
      <div class="logo-group">
        <Swiper class="logo-switch" :options="logoSwiperOptions">
          <SwiperSlide>
            <div class="logo-0" />
          </SwiperSlide>
          <SwiperSlide>
            <div class="logo-1" />
          </SwiperSlide>
        </Swiper>
        <div :class="['logo-text', 'logo-text__'.concat(locale)]" />
      </div>
      <div class="top-right">
        <Swiper class="top-desc" :options="topDescSwiperOptions">
          <SwiperSlide>
            <div :class="['desc-0', 'desc-0__'.concat(locale)]" />
          </SwiperSlide>
          <SwiperSlide>
            <div :class="['desc-1', 'desc-1__'.concat(locale)]" />
          </SwiperSlide>
          <SwiperSlide>
            <div :class="['desc-2', 'desc-2__'.concat(locale)]" />
          </SwiperSlide>
          <SwiperSlide>
            <div :class="['desc-3', 'desc-3__'.concat(locale)]" />
          </SwiperSlide>
        </Swiper>
        <div class="top-nav">
<!--          <RouterLink to="/gtctt/sbvportalintro">Introduction</RouterLink>-->
          <RouterLink to="/sitemap">Giới thiệu Cổng TT Sơ đồ</RouterLink>
<!--          <a @click="switchLocale">{{ $t('lang') }}</a>-->
        </div>
      </div>
    </header>
    <div class="container-content">
      <RouterView />
    </div>
    <footer class="bottom-footer">
      <section class="bottom-footer--links">
        <a href="#" class="bottom-footer--link">
          <i class="icon" />
          <span>Hệ thống các<br />Tổ chức tín dụng</span>
        </a>
        <a href="#" class="bottom-footer--link">
          <i class="icon" />
          <span>Danh bạ Liên hệ</span>
        </a>
        <a href="#" class="bottom-footer--link">
          <i class="icon" />
          <span>Diễn đàn NHNN</span>
        </a>
        <a href="#" class="bottom-footer--link">
          <i class="icon" />
          <span>Trao đổi thư</span>
        </a>
      </section>
      <section class="bottom-footer--info">
        <div class="bottom-footer--copyright">
          © CỔNG THÔNG TIN ĐIỆN TỬ NGÂN HÀNG NHÀ NƯỚC VIỆT NAM
        </div>
        <div class="bottom-footer--contact">
          <span>Địa chỉ : Số 49 Lý Thái Tổ - Hoàn Kiếm - Hà Nội</span>
          <span>Thường trực Ban biên tập : (84 - 243) 266.9435</span>
          <span>Email : thuongtrucweb@sbv.gov.vn</span>
        </div>
        <div class="bottom-footer--statement">
          Số điện thoại tổng đài Ngân hàng Nhà nước: (84 - 243) 936.6306
          <br />
          Đầu mối tiếp nhận thông tin về sự cố an toàn thông tin: số điện thoại: (+84)84.859.5983,
          email: antt@sbv.gov.vn
        </div>
      </section>
    </footer>
<!--    <a-->
<!--      class="service-float-ball"-->
<!--      href="https://secure.livechatinc.com/licence/18228705/open_chat.cgi"-->
<!--      target="_blank"-->
<!--    >-->
<!--      <img src="/images/service.svg" />-->
<!--    </a>-->
  </div>
</template>
<script>
import { switchLocale } from '@/locales'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      logoSwiperOptions: {
        allowTouchMove: false,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        speed: 2000,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      },
      topDescSwiperOptions: {
        allowTouchMove: false,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        speed: 2000,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      },
    }
  },
  computed: {
    ...mapState(['locale']),
  },
  methods: {
    switchLocale,
  },
}
</script>
<style lang="less">
@import './style.less';
</style>
