<template>
  <section>
    <div class="news-list news-list__grid news-list__grid-backover">
      <div v-for="item in news" :key="item.id" class="news-list-item">
        <div class="news-list-item--header">
          <RouterLink :to="$route.path.concat('/detail')">{{ item.title }}</RouterLink>
          <em v-if="item.isNew" class="news-list-item--new" />
        </div>
        <img class="news-list-item--img news-list-item--img__120" :src="item.img" />
        <div class="news-list-item--content">{{ item.content }}</div>
        <div class="news-list-item--extra">
          <span>({{ item.date }})</span>
        </div>
      </div>
    </div>
    <List header="Other" :data-source="other" />
  </section>
</template>
<script>
import { List } from '@/components'

export default {
  components: {
    List,
  },
  data() {
    return {
      news: [
        {
          id: '1',
          title:
            'Deputy Governor Pham Thanh Ha joins Prime Minister’s official visit to Republic of Korea',
          content:
            'From June 30 to July 3, 2024, Deputy Governor Pham Thanh Ha of the State Bank of Vietnam (SBV) accompanied Prime Minister Pham Minh Chinh in his official visit to the Republic of Korea (ROK). This was the first official visit of a Vietnamese Government leader to the ROK since the two countries upgraded their bilateral relationship to Comprehensive Strategic Partnership in 2022.',
          isNew: true,
          date: '07/02/2024',
        },
        {
          id: '2',
          title: 'Deputy Governor Pham Thanh Ha meets Group Chairman of Standard Chartered PLC.',
          content:
            'On June 28, 2024, at the headquarters of the State Bank of Vietnam (SBV), Deputy Governor Pham Thanh Ha had a meeting with Mr. Joe Vinals, Group Chairman of Standard Chartered PLC.',
          isNew: true,
          date: '07/01/2024',
        },
        {
          id: '3',
          title: 'SBV’s guidance on implementation of Decision 2345/QD-NHNN',
          content:
            'On June 25, 2024, the State Bank of Vietnam (SBV) issued Document No. 5262/NHNN-CNTT providing guidance to the credit institutions, the foreign bank branches, and the payment intermediary service providers on the implementation of Decision 2345/QD-NHNN.',
          date: '06/28/2024',
        },
        {
          id: '4',
          title: 'Deputy Governor Pham Thanh Ha meets EU-ABC',
          content:
            'On June 27, 2024, at the headquarters of the State Bank of Vietnam (SBV), Deputy Governor Pham Thanh Ha had a meeting with a delegation of the EU-ASEAN Business Council (EU-ABC).',
          date: '06/28/2024',
        },
        {
          id: '5',
          title:
            'Deputy Governor Pham Thanh Ha meets IMF Executive Director for Southeast Asia Voting Group',
          content:
            'On June 25, 2024, at the headquarters of the State Bank of Vietnam (SBV), Deputy Governor Pham Thanh Ha had a meeting with Ms. Yati Kurniati, IMF Executive Director for the Southeast Asia Voting Group (SEAVG).',
          date: '06/27/2024',
        },
        {
          id: '6',
          title: 'Disseminating contents of Decree No. 52/2024/ND-CP',
          content:
            'On June 21, 2024, the State Bank of Vietnam (SBV) organized a Conference to disseminate information of Decree No. 52/ND-CP dated May 15, 2024 of the Government on non-cash payment. The Conference was held in hybrid format and virtually connected with the SBV municipal and provincial branches.',
          date: '06/25/2024',
        },
      ],
      other: [
        {
          id: '1',
          title:
            'Inception Workshop “Technical Assistance on Expanding Inclusive and Climate Finance”',
          date: '06/24/2024',
        },
        {
          id: '2',
          title: 'SBV Governor Nguyen Thi Hong meets IMF’s Article IV Mission',
          date: '06/24/2024',
        },
        {
          id: '3',
          title: 'Continuing to implement solutions for promoting credit growth',
          date: '06/20/2024',
        },
        {
          id: '4',
          title: 'Conducting robust measures to promote credit growth',
          date: '06/20/2024',
        },
        {
          id: '5',
          title: 'Enhancing security and safety for non-cash payment transactions',
          date: '06/18/2024',
        },
        {
          id: '6',
          title:
            'Implementing solutions for safety and security in online and banking card payment',
          date: '06/14/2024',
        },
        {
          id: '7',
          title:
            'SBV announces Appointment Decision of Deputy Director of SBV Hoa Binh provincial branch',
          date: '06/12/2024',
        },
        {
          id: '8',
          title:
            'SBV has sufficient resources and determination to stabilize market, control gap between domestic and international gold prices at appropriate level',
          date: '06/07/2024',
        },
        {
          id: '9',
          title:
            'Sustainability reporting can contribute to realizing Sustainable Development Goals in Vietnam by 2030',
          date: '06/07/2024',
        },
        {
          id: '10',
          title:
            'SBV announces Appointment Decision of Deputy Director General of Reserve Management Department',
          date: '06/06/2024',
        },
      ],
    }
  },
}
</script>
