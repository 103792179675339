<template>
  <section>
    <div class="news-list news-list__grid news-list__grid-backover">
      <div v-for="item in list" :key="item.id" class="news-list-item">
        <div class="news-list-item--header">
          <RouterLink :to="$route.path.concat('/').concat(item.id)">{{ item.title }}</RouterLink>
          <em v-if="item.isNew" class="news-list-item--new" />
        </div>
        <img class="news-list-item--img news-list-item--img__120" :src="item.titlePic" />
        <div class="news-list-item--content">{{ item.titleDescription }}</div>
        <div class="news-list-item--extra">
          <span>{{ getDate(item.gmtCreate) }}</span>
        </div>
      </div>
    </div>
    <List header="Other" :offset="6" />
  </section>
</template>
<script>
import { List } from '@/components'
import dayjs from 'dayjs'

export default {
  components: {
    List,
  },
  props: {
    list: {
      type: Array,
      default: [],
    },
  },
  methods: {
    getDate(ts) {
      return dayjs(ts).format('(DD/MM/YYYY)')
    },
  },
}
</script>
