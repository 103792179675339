<template>
  <Spin :spinning="loading">
    <div class="panel-body">
      <!-- Temporary display, actual content should be obtained from the API -->
      <div class="news-header">
        <h1>
          {{ content.title }}
        </h1>
        <div class="news-header--bar">
          <div class="news-header--date">{{ getDate(content.gmtCreate) }}</div>
          <div class="news-header--tools">
            <div class="news-header--font-size">
              Font size:
              <span @click="fontSizeChange(-0.1)" @selectstart.prevent>A-</span>
              <span @click="fontSizeChange(0.1)" @selectstart.prevent>A+</span>
            </div>
          </div>
        </div>
      </div>
      <div class="news-content" :style="{ zoom: fontSize }" v-html="content.titleContent" />
    </div>
    <List header="Other" :offset="6" />
  </Spin>
</template>
<script>
import { List } from '@/components'
import { GET_articleDetailAsync } from '@/service'
import { Spin } from 'ant-design-vue'
import dayjs from 'dayjs'

export default {
  components: {
    Spin,
    List,
  },
  data() {
    return {
      loading: false,
      content: {},
      fontSize: 1,
    }
  },
  watch: {
    '$route.params'() {
      this.getArticleContentAsync()
    },
  },
  created() {
    this.getArticleContentAsync()
  },
  methods: {
    async getArticleContentAsync() {
      const { id } = this.$route.params
      if (id) {
        this.loading = true
        const { data } = await GET_articleDetailAsync(id)
        const { Data } = data
        this.content = Data
        this.loading = false
      }
    },
    getDate(ts) {
      return dayjs(ts).format('DD/MM/YYYY')
    },
    fontSizeChange(d) {
      this.fontSize += d
    },
  },
}
</script>
