<template>
  <div class="tabs">
    <div class="tabs-nav">
      <RouterLink class="tabs-nav-item" to="/prerel/wiobo"
        >Weekly Information on Banking Operations</RouterLink
      >
      <RouterLink class="tabs-nav-item" to="/prerel/otherpre">Other Press Releases</RouterLink>
    </div>
    <div class="tabs-content">
      <List border download :data-source="list" />
    </div>
  </div>
</template>
<script>
import { List } from '@/components'

export default {
  components: {
    List,
  },
  data() {
    return {
      list: [
        {
          id: '1',
          title: 'Press release on issuance of Circular No. 15/2024/TT-NHNN',
          date: '07/03/2024',
        },
        {
          id: '2',
          title: 'Press release on issuance of Circular No. 13/2024/TT-NHNN',
          date: '07/02/2024',
        },
      ],
    }
  },
}
</script>
