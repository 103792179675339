export default [
  {
    id: '/Introduction',
    name: 'Introduction',
    path: '/Introduction',
    hidden: true,
  },
  {
    id: '/sitemap',
    name: 'Sitemap',
    path: '/sitemap',
    hidden: true,
  },
  {
    id: '/cpi',
    name: 'CPI',
    path: '/cpi',
    hidden: true,
  },
  {
    id: '/exchangerate',
    name: 'Exchange Rate',
    path: '/exchangerate',
    hidden: true,
  },
  {
    id: '/ir',
    name: 'Interest Rate',
    path: '/ir',
    hidden: true,
  },
  {
    id: '/rere',
    name: 'Reserve Requirement Ratios',
    path: '/rere',
    hidden: true,
  },
  {
    id: '/museum',
    name: 'Museum',
    path: '/museum',
    hidden: true,
    children: [
      {
        id: '/museum/hadotcb',
        name: 'History and Development of the Central Bank',
        path: '/museum/hadotcb',
      },
      {
        id: '/museum/hovnc',
        name: "History of Vietnam's Currencies",
        path: '/museum/hovnc',
      },
    ],
  },
  {
    id: '/public',
    name: 'Publications',
    path: '/public',
    hidden: true,
    children: [
      {
        id: '/public/nreport',
        name: 'Annual Reports',
        path: '/public/nreport',
      },
      {
        id: '/public/opublic',
        name: 'Other Publications',
        path: '/public/opublic',
      },
    ],
  },
]
