<template>
  <Spin :spinning="loading">
    <ul class="menu">
      <li v-for="(item, i) in menu" :key="item.id">
        <i class="menu-icon" :class="'menu-icon--' + (i + 1)" />
        <RouterLink class="menu-link" :to="'/article/'.concat(item.id)">{{ item.name }}</RouterLink>
      </li>
    </ul>
  </Spin>
</template>
<script>
import { Spin } from 'ant-design-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Spin,
  },
  computed: {
    ...mapGetters({
      loading: 'getMenuLoading',
      menu: 'getMenu',
    }),
  },
  watch: {
    '$route.path'() {
      this.getActiveKeys()
    },
    menu() {
      this.getActiveKeys()
    },
  },
  created() {
    this.getActiveKeys()
  },
  methods: {
    getActiveKeys() {
      const { typeId } = this.$route.params
      const _id = Number(typeId)
      const activeKeys = []
      const deepFind = (map) => {
        for (const s of map) {
          if (s.id === _id) {
            activeKeys.unshift(s.id)
            return true
          } else if (s.children) {
            const res = deepFind(s.children)
            if (res) {
              activeKeys.unshift(s.id)
              return true
            }
          }
        }
      }

      deepFind(this.menu)

      this.activeKeys = activeKeys
    },
  },
}
</script>
<style lang="less">
@import url(./style.less);
</style>
