<template>
  <div class="col-container">
    <div class="col-1">
      <Nav />
      <Menu />
      <WebLinks />
    </div>
    <div class="col-3">
      <div class="breadcrumb">
        <div class="breadcrumb-static">
          <RouterLink to="/">Trang chủ</RouterLink>
        </div>
        <div class="breadcrumb-dynamics">
          <template v-for="(item, index) in breadcrumb">
            <RouterLink
              v-if="index < breadcrumb.length - 1 || $route.params.id"
              :key="item.id"
              :to="item.path"
              >{{ item.title }}</RouterLink
            >
            <span v-else :key="item.id">{{ item.title }}</span>
          </template>
        </div>
      </div>
      <div class="panel">
        <div class="panel-header panel-header__upper">{{ title }}</div>
        <Spin :spinning="!!detail.loading">
          <section style="min-height: 40px">
            <component :is="detail.componentName" :id="detail.id" :list="detail.list" />
          </section>
        </Spin>
      </div>
    </div>
  </div>
</template>
<script>
import { Menu, Nav, WebLinks } from '@/components'
import {
  ArticleContent,
  CPI,
  Museum,
  News,
  NewsContent,
  Publications,
  Releases,
  Sitemap,
} from '@/components/views'
import { GET_articleListAsync } from '@/service'
import sitemap from '@/sitemap'
import { Spin } from 'ant-design-vue'
import { upperFirst } from 'lodash'
import { mapGetters } from 'vuex'

const extraComponents = {
  Sitemap,
  NewsLatestnews: News,
  NewsLatestnewsDetail: NewsContent,
  NewsOthernews: News,
  NewsOthernewsDetail: NewsContent,
  PrerelWiobo: Releases,
  PrerelOtherpre: Releases,
  PublicNreport: Publications,
  PublicOpublic: Publications,
  Cpi: CPI,
  MuseumHadotcb: Museum,
  MuseumHovnc: Museum,
}

const contentNameMapping = Object.keys(extraComponents)

export default {
  components: {
    Spin,
    Menu,
    Nav,
    WebLinks,
    ArticleContent,
    News,
    NewsContent,
    ...extraComponents,
  },
  data() {
    return {
      breadcrumb: [],
      title: '',
      detail: {
        componentName: '',
        id: 0,
        data: [],
        loading: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      nav: 'getNav',
      menu: 'getMenu',
    }),
    sitemapTree() {
      return [...this.nav, ...this.menu, ...sitemap]
    },
    customComponentName() {
      const name = this.$route.path.split('/').slice(1).map(upperFirst).join('')
      if (contentNameMapping.includes(name)) {
        return name
      }
    },
  },
  watch: {
    '$route.path'() {
      this.initial()
    },
    nav() {
      this.initial(false)
    },
  },
  created() {
    this.initial()
  },
  methods: {
    initial(list = true) {
      const { typeId, id } = this.$route.params
      this.createBreadcrumb(typeId)
      if (!id) {
        if (list) {
          this.getArticleListAsync(typeId)
        }
      } else {
        this.detail = {
          componentName: this.customComponentName || 'NewsContent',
        }
      }
    },
    createBreadcrumb(typeId) {
      const breadcrumb = []
      const _id = Number(typeId)
      const { path } = this.$route
      const deepFind = (map) => {
        for (const s of map) {
          if (s.id === _id || s.path === path) {
            breadcrumb.unshift({
              id: s.id,
              title: s.name,
              path: s.path || '/article/'.concat(s.cid),
            })
            return true
          } else if (s.children) {
            const res = deepFind(s.children)
            if (res) {
              breadcrumb.unshift({
                id: s.id,
                title: s.name,
                path: s.path || '/article/'.concat(s.cid),
              })
              return true
            }
          }
        }
      }

      deepFind(this.sitemapTree)

      this.breadcrumb = breadcrumb
      this.title = breadcrumb[breadcrumb.length - 1]?.title
    },
    async getArticleListAsync(typeId) {
      if (typeId) {
        this.detail = {
          componentName: '',
          loading: true,
        }
        const { data } = await GET_articleListAsync({
          articleType: typeId,
          size: 10,
        })
        const { Data } = data
        console.log(Data)
        switch (Data.totalElements) {
          case 0:
            this.detail = {
              loading: false,
            }
            break
          case 1:
            const [item] = Data.content
            this.detail = {
              componentName: 'ArticleContent',
              id: item.id,
              loading: false,
            }
            break
          default:
            this.detail = {
              componentName: 'News',
              list: Data.content,
              loading: false,
            }
            break
        }
      } else {
        this.detail = {
          componentName: this.customComponentName,
        }
      }
    },
  },
}
</script>
