<template>
  <Spin :spinning="loading">
    <ul class="nav">
      <template v-for="item in nav">
        <li :key="item.id" :class="{ active: activeKeys.includes(item.id) }">
          <RouterLink :to="'/article/'.concat(item.cid)">{{ item.name }}</RouterLink>
          <ul v-if="item.children?.length && !item.hiddenChildren" class="sub-nav">
            <li
              v-for="_item in item.children"
              :key="_item.id"
              :class="{ active: activeKeys.includes(_item.id) }"
            >
              <RouterLink :to="'/article/'.concat(_item.id)">{{ _item.name }}</RouterLink>
              <ul v-if="_item.children?.length" class="sub-sub-nav">
                <li
                  v-for="__item in _item.children"
                  :key="__item.id"
                  :class="{ active: activeKeys.includes(__item.id) }"
                >
                  <RouterLink :to="'/article/'.concat(__item.id)">{{ __item.name }}</RouterLink>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </template>
    </ul>
  </Spin>
</template>
<script>
import { Spin } from 'ant-design-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Spin,
  },
  data() {
    return {
      activeKeys: [],
    }
  },
  computed: {
    ...mapGetters({
      loading: 'getNavLoading',
      nav: 'getNav',
    }),
  },
  watch: {
    '$route.path'() {
      this.getActiveKeys()
    },
    nav() {
      this.getActiveKeys()
    },
  },
  created() {
    this.getActiveKeys()
  },
  methods: {
    getActiveKeys() {
      const { typeId } = this.$route.params
      const _id = Number(typeId)
      const activeKeys = []
      const deepFind = (map) => {
        for (const s of map) {
          if (s.id === _id) {
            activeKeys.unshift(s.id)
            return true
          } else if (s.children) {
            const res = deepFind(s.children)
            if (res) {
              activeKeys.unshift(s.id)
              return true
            }
          }
        }
      }

      deepFind(this.nav)

      this.activeKeys = activeKeys
    },
  },
}
</script>
<style lang="less">
@import url(./style.less);
</style>
