import { getLocale } from '@/locales'
import axios from 'axios'

const api = axios.create()

api.interceptors.request.use(
  (config) => {
    return {
      ...config,
      headers: {
        lang: getLocale(),
      },
    }
  },
  (error) => {
    return Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default api
