import { GET_articleListAsync } from '@/service'
import { Empty, Pagination, Spin } from 'ant-design-vue'
import dayjs from 'dayjs'
import './style.less'

export default {
  props: {
    border: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
    },
    offset: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    download: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      dataSource: [],
      pageNumber: 0,
      total: 0,
    }
  },
  created() {
    this.getArticleListAsync()
  },
  methods: {
    async getArticleListAsync() {
      const { typeId } = this.$route.params
      const { offset, pageNumber, pageSize } = this
      this.loading = true
      const { data } = await GET_articleListAsync({
        articleType: typeId,
        offset,
        page: pageNumber,
        size: pageSize,
      })
      const { Data } = data
      this.total = Data.totalElements
      this.dataSource = Data.content
      this.loading = false
    },
    getDate(ts) {
      return dayjs(ts).format('(DD/MM/YYYY)')
    },
    onPageChange(page) {
      this.pageNumber = page - 1
      this.getArticleListAsync()
    },
  },
  render() {
    const { border, header, offset, download, loading, dataSource, pageNumber, pageSize, total } =
      this
    const { typeId } = this.$route.params

    return (
      <div
        class={[
          'list',
          {
            list__bordered: border,
          },
        ]}
      >
        {header && (
          <div class="list-header">
            <i class="list-header--icon" />
            <span>{header}</span>
          </div>
        )}
        <Spin spinning={loading}>
          <div class="list-content">
            {!loading && !dataSource.length && <Empty />}
            <ul>
              {dataSource.map((item) => (
                <li key={item.id}>
                  <div class="list-title">
                    <RouterLink to={'/article/'.concat(typeId).concat('/').concat(item.id)}>
                      {item.title}
                    </RouterLink>
                    <span>{this.getDate(item.gmtCreate)}</span>
                  </div>
                  {download && (
                    <div class="list-download">
                      <RouterLink to="#" />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </Spin>
        <div class="list-pager">
          <span />
          <Pagination
            current={pageNumber + 1}
            pageSize={pageSize}
            total={total}
            onChange={this.onPageChange}
          />
        </div>
      </div>
    )
  },
}
