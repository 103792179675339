import { getLocale } from '@/locales'
import { GET_typeAllAsync } from '@/service'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state() {
    return {
      navLoading: true,
      nav: [],
      menuLoading: true,
      menu: [],
      locale: getLocale(),
    }
  },
  mutations: {
    setNavLoading(state, payload) {
      state.navLoading = payload
    },
    setNav(state, payload) {
      state.nav = payload
    },
    setMenuLoading(state, payload) {
      state.menuLoading = payload
    },
    setMenu(state, payload) {
      state.menu = payload
    },
    setLocale(state, payload) {
      state.locale = payload
    },
  },
  actions: {
    async fetchNavAsync({ commit }) {
      try {
        commit('setNavLoading', true)
        const { data } = await GET_typeAllAsync({
          state: 1,
          parentId: 180,
        })
        if (data.Code === 'OK') {
          const { Data } = data
          /**
           * Directly reading the first ID of a child from the first level menu
           */
          const res = Data.map((item) => {
            const [child] = item.children
            if (child) {
              return {
                ...item,
                cid: child.id,
              }
            }
            return {
              ...item,
              cid: item.id,
            }
          })
          commit('setNav', res)
        }
      } catch {
      } finally {
        commit('setNavLoading', false)
      }
    },
    async fetchMenuAsync({ commit }) {
      try {
        commit('setMenuLoading', true)
        const { data } = await GET_typeAllAsync({
          state: 1,
          parentId: 181,
        })
        if (data.Code === 'OK') {
          const { Data } = data
          commit('setMenu', Data)
        }
      } catch {
      } finally {
        commit('setMenuLoading', false)
      }
    },
    setLocale({ commit }, locale) {
      commit('setLocale', locale)
    },
  },
  getters: {
    getNavLoading: (state) => state.navLoading,
    getNav(state) {
      if (!state.nav.length && state.navLoading) {
        store.dispatch('fetchNavAsync')
      }
      return state.nav
    },
    getMenuLoading: (state) => state.menuLoading,
    getMenu: (state) => {
      if (!state.menu.length && state.menuLoading) {
        store.dispatch('fetchMenuAsync')
      }
      return state.menu
    },
  },
})

export default store
