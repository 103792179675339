import 'ant-design-vue/dist/antd.css'
import 'swiper/css/swiper.css'
import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import App from './App.vue'
import './global.less'
import i18n from './locales'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.use(VueAwesomeSwiper /* { default options with global component } */)
import * as filters from './filters' // global filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')

export default app
