import sitemap from '@/sitemap'
import { RouterLink } from 'vue-router'
import { mapGetters } from 'vuex'
import './style.less'

export default {
  computed: {
    ...mapGetters({
      nav: 'getNav',
    }),
    sitemapTree() {
      return [...this.nav, ...sitemap]
    },
  },
  methods: {
    sitemapDomFactory(item) {
      if (item.children?.length) {
        return (
          <ul class="site-map">
            {item.children.map((_item) => (
              <li key={_item.id}>
                <RouterLink to={_item.path || '/article/'.concat(item.cid || item.id)}>
                  {_item.name}
                </RouterLink>
                {this.sitemapDomFactory(_item)}
              </li>
            ))}
          </ul>
        )
      }

      return undefined
    },
  },
  render() {
    const { sitemapTree } = this

    return (
      <div class="panel-body">
        <div class="panel-group">
          {sitemapTree
            .filter((item) => !item.hidden && !item.hiddenInSiteMap)
            .map((item) => (
              <div key={item.id} class="panel">
                <RouterLink class="panel-header" to={item.path || '/article/'.concat(item.cid)}>
                  {item.name}
                </RouterLink>
                <div class="panel-body">{this.sitemapDomFactory(item)}</div>
              </div>
            ))}
        </div>
      </div>
    )
  },
}
